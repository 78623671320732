import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ValidationService } from '../../validators/validation.service';

@Component({
  selector: 'control-messages',
  template: `<div class="text-msg-form" *ngIf="errorMessage !== null">{{errorMessage}}</div>`
})
export class FormControlMessageComponent {

  // developed by luisAlvarado4077@gmail.com
  @Input() control: FormControl = new FormControl;
  constructor() { }

  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.invalid && (this.control.dirty || this.control.touched)) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }

}
